.mb-0 {
  margin-bottom: 0em !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.mb-2 {
  margin-bottom: 2em !important;
}
.mb-3 {
  margin-bottom: 3em !important;
}
.mb-5 {
  margin-bottom: 5em !important;
}

.mt-0 {
  margin-top: 0em !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mt-2 {
  margin-top: 2em !important;
}
.mt-3 {
  margin-top: 3em !important;
}
.mt-5 {
  margin-top: 5em !important;
}

.ms-0 {
  margin-left: 0em !important;
}
.ms-1 {
  margin-left: 1em !important;
}
.ms-2 {
  margin-left: 2em !important;
}
.ms-3 {
  margin-left: 3em !important;
}
.ms-5 {
  margin-left: 5em !important;
}

.me-0 {
  margin-right: 0em !important;
}
.me-1 {
  margin-right: 1em !important;
}
.me-2 {
  margin-right: 2em !important;
}
.me-3 {
  margin-right: 3em !important;
}
.me-5 {
  margin-right: 5em !important;
}

.me-small-1{
  margin-right: 0.3em !important;
}

.me-small-2{
  margin-right: 0.5em !important;
}

.mb-small-1{
  margin-bottom: 0.3em !important;
}

.mb-small-2{
  margin-bottom: 0.5em !important;
}

.mt-small-1{
  margin-top: 0.3em !important;
}

.mt-small-2{
  margin-top: 0.5em !important;
}