@import "./Margins.css";
@import "./Paddings.css";
@import "./Fonts.css";
@import "./Colors.css";

@media (min-width: 1024px) {
  .ui.container .ui.header.home-header {
    margin-bottom: 2 !important;
    font-size: 5.5em !important;
    font-weight: 700 !important;
    text-align: start !important;
  }
}

/* SM */
@media (max-width: 1024px) {
  .ui.container .ui.header.home-header {
    margin-bottom: 2 !important;
    font-size: 11em !important;
    font-weight: 700 !important;
    text-align: start !important;
  }
}

.sm-list{
  font-size: 1.5em;
  line-height: 1.3em;
}
.sm-list-title{
  font-size: 1.1em;
  line-height: 1.2em;
}
