@media (min-width: 1024px) {
  .btn-scroll {
    position: fixed;
    bottom: 40px;
    z-index: 1;
    left: 93.5%;
    cursor: pointer;
  }
}

/* SM */
@media (max-width: 1024px) {
  .btn-scroll {
    position: fixed;
    bottom: 40px;
    z-index: 1;
    left: 80%;
    cursor: pointer;
    padding-bottom: 10px !important;
    }

  .arrow.up.icon{
    font-size: 50px;
  }
}
