.pb-0 {
  padding-bottom: 0em !important;
}

.pb-1 {
  padding-bottom: 1em !important;
}

.pb-2 {
  padding-bottom: 2em !important;
}

.pb-3 {
  padding-bottom: 3em !important;
}

.pb-5 {
  padding-bottom : 5em !important;
}

.pt-0 {
  padding-top: 0em !important;
}

.pt-1 {
  padding-top: 1em !important;
}

.pt-2 {
  padding-top: 2em !important;
}

.pt-3 {
  padding-top: 3em !important;
}

.pt-5 {
  padding-top: 5em !important;
}

.pt-0 {
  padding-left: 0em !important;
}

.ps-1 {
  padding-left: 1em !important;
}

.ps-2 {
  padding-left: 2em !important;
}

.ps-3 {
  padding-left: 3em !important;
}

.ps-5 {
  padding-left: 5em !important;
}

.pe-0 {
  padding-right: 0em !important;
}

.pe-1 {
  padding-right: 1em !important;
}

.pe-2 {
  padding-right: 2em !important;
}

.pe-3 {
  padding-right: 3em !important;
}

.pe-5 {
  padding-right: 5em !important;
}

.pe-small-1{
  padding-right: 0.3em !important;
}

.pe-small-2{
  padding-right: 0.5em !important;
}