.fs-1{
    font-size: xx-large !important;
}
.fs-2{
    font-size: x-large !important;
}
.fs-3{
    font-size: large !important;
}
.fs-4{
    font-size: larger !important;
}
.fs-5{
    font-size: medium !important;
}
.fs-6{
    font-size: small !important;
}

.fw-n{
    font-weight: normal !important;
}

.fw-b{
    font-weight: bold !important;
}