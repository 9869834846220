.homepage .ui.segment.seg-header {
  min-height: 700px !important;
  padding: "1em 0em" !important;
}

@media (min-width: 1024px) {
  .homepage .ui.segment.seg-header {
    min-height: 900px !important;
    padding: "1em 0em" !important;
  }
}

@media (max-width: 1024px) {
  .homepage .ui.segment.seg-header {
    min-height: 700px !important;
  }
}