.text-red {
  color: red !important;
}
.text-green {
  color: green !important;
}
.text-yellow {
  color: yellow !important;
}
.text-blue {
  color: blue !important;
}
.text-black {
  color: black !important;
}
.text-white {
  color: white !important;
}
.text-grey {
  color: grey !important;
}
.text-brown {
  color: brown !important;
}
.text-orange {
  color: orange !important;
}
.text-purple {
  color: purple !important;
}
.text-olive {
  color: olive !important;
}
.text-teal {
  color: teal !important;
}
.text-cyan {
  color: cyan !important;
}
.text-violet {
  color: violet !important;
}
.text-pink {
  color: pink !important;
}
