/* Computer */
@media (min-width: 1024px) {
  .ui.container .ui.header.header1 {
    margin-top: 1.4em !important;
    margin-bottom: 0 !important;
    font-size: 7em !important;
    font-weight: 700 !important;
  }

  .ui.container .ui.header.subheader1 {
    margin-top: 0.5em !important;
    font-weight: normal !important;
    font-size: 2em !important;
  }
}

/* Mobile */
@media (max-width: 1024px) {
  .ui.container .ui.header.header1 {
    margin-top: 0.6em !important;
    margin-bottom: 0 !important;
    font-size: 9.6em !important;
    font-weight: 900 !important;
  }

  .ui.container .ui.header.subheader1 {
    margin-top: 0.5em !important;
    font-size: 4em;
    font-weight: normal !important;
  }
}
